import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import seoShape from 'shapes/seoShape';
import config from 'config';
import { getRawText } from 'utils/rawTextWordCount';
import { removeTrailingBackslash } from 'utils/linkUtils';
import dayjs from 'dayjs';

SEO.propTypes = {
  seo: seoShape,
  title: PropTypes.string,
  thumbnail: PropTypes.string,
  thumbnailAlt: PropTypes.string,
  structuredData: PropTypes.any,
  titleSuffix: PropTypes.string
};

function SEO({ seo, title, thumbnail, thumbnailAlt, structuredData, titleSuffix = '' }) {
  const history = useHistory();
  const location = useLocation();
  const seoFallbackImg = 'https://images.contentstack.io/v3/assets/blt40263f25ec36953f/bltd2bb0043dd4ef3ab/63a4b25f6c600a5006e7f22e/tastylive-fallback-horizontal-white.png'
  const fallbackImg = thumbnail ? thumbnail : seoFallbackImg;
  const shareImgParams = '?width=1200&height=628&disable=upscale&fit=bounds';
  const fallbackAlt = thumbnailAlt ? thumbnailAlt : 'uploaded image';

  const today = dayjs().tz('America/Chicago');
  const todayIndex = today.unix();

  if (!seo) {
    return null;
  }

  const {
    ogDescription,
    ogImage,
    ogTitle,
    seoMetaDescription,
    seoTitle,
    twitterCard,
    twitterDescription,
    twitterImage,
    twitterImageAlt,
    canonicalUrl,
    twitterTitle
  } = seo;

  // Building out alternative canonical url and getting page param if exists
  const pageParam = qs.parse(location.search);
  const page = pageParam.page && pageParam.page !== '1' ? pageParam.page : null;
  const altCanonical = removeTrailingBackslash(
    `${config.prodSiteBaseUrl}${history.location.pathname || ''}${page ? '?page=' + page : ''}`
  );

  return (
    <>
      <Helmet titleTemplate={`%s | tastylive`}>
        <title>{seoTitle || title}{titleSuffix}</title>
        <html lang='en' />
        <meta charSet='utf-8' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
        <meta name='description' content={seoMetaDescription || `content related to ${seoTitle || title}`} />
        <meta property='og:title' content={ogTitle || seoTitle || title} />
        <meta property='og:image' content={(get(ogImage, 'url') || fallbackImg + shareImgParams) + `#time=${todayIndex}`} />
        <meta property='og:description' content={ogDescription || seoMetaDescription} />
        <meta property='og:type' content='website' />
        <meta name='twitter:card' content={twitterCard || 'summary_large_image'} />
        <meta name='twitter:creator' content='@tastyliveshow' />
        <meta name='twitter:title' content={twitterTitle || seoTitle || title} />
        <meta name='twitter:description' content={twitterDescription || seoMetaDescription} />
        <meta name='twitter:image' content={(get(twitterImage, 'url') || fallbackImg + shareImgParams) + `#time=${todayIndex}`} />
        <meta name='twitter:image:alt' content={twitterImageAlt || fallbackAlt} />
        {/* Canonical tag for page crawling */}
        <link
          rel='canonical'
          href={
            canonicalUrl ? removeTrailingBackslash(canonicalUrl) + (page ? '?page=' + page : '') : altCanonical
          }
        />
      </Helmet>
      {/*Structured Data passed in as prop*/}
      {structuredData && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: getRawText(JSON.stringify(structuredData)) }}
        />
      )}
      {/* Structured Data passed in from CS SEO fields */}
      {seo.structuredData && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(seo.structuredData) }}
        />
      )}
    </>
  );
}

export default React.memo(SEO);
